import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  formatCurrencyInt, formatPercent, maskCnpj,
} from '@core/utils/utils'
import { endOfMonth, format, startOfMonth } from 'date-fns'

export default function useUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'workplace.name', label: 'Unidade', sortable: true },
    {
      key: 'amount', label: 'Lançado', formatter: formatCurrencyInt, sortable: true,
    },
    {
      key: 'amount_requested', label: 'Adiantado', formatter: formatCurrencyInt, sortable: true,
    },
    {
      key: 'payroll', label: 'Folha', formatter: formatCurrencyInt, sortable: true,
    },
    {
      key: 'percent_amount_payroll', label: 'L vs F', formatter: formatPercent, sortable: true,
    },
    {
      key: 'percent_amount_requested_amount_payroll', label: 'A vs F', formatter: formatPercent, sortable: true,
    },
  ]
  const showImediato = ref(false)
  const reportList = ref([])
  const institutionId = ref(null)
  const workplaceId = ref(null)
  const isSortDirDesc = ref(true)
  const sortBy = ref('workplace.name')
  const reportHeaders = ref({
    total_amount: 0,
    total_amount_requested: 0,
    total_payroll: 0,
  })
  const institutionOptions = ref([])
  const workplaceOptions = ref([])
  const beginDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const endDate = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'))

  const checkAll = computed({
    get: () => {
      if (reportList.value.length === 0) return false
      return reportList.value.filter(user => user.checked).length === reportList.value.length
    },
    set: val => {
      reportList.value.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.checked = val
      })
    },
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const getReport = (ctx, callback) => {
    store
      .dispatch('app-reports/payroll', {
        start_date: beginDate.value || undefined,
        end_date: endDate.value || undefined,
        show_imediato: showImediato.value ? 1 : 0,
        institution: institutionId.value ? institutionId.value.value : '',
        workplace: workplaceId.value ? workplaceId.value.value : '',
      })
      .then(response => {
        const workplaces = _.orderBy(response.data.data.workplaces, [sortBy.value], [isSortDirDesc.value ? 'desc' : 'asc'])
        reportList.value = workplaces
        reportHeaders.value = {
          total_amount: response.data.data.total_amount,
          total_amount_requested: response.data.data.total_amount_requested,
          total_payroll: response.data.data.total_payroll,
        }
        callback(reportList.value)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchInstitutions = () => {
    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })
  }

  const fetchWorkplaces = () => {
    if (institutionId.value) {
      store.dispatch('app-reports/fetchWorkplaceByInstitution', { institution_id: institutionId.value.value })
        .then(response => {
          workplaceOptions.value = response.data.data.map(w => ({
            value: w.id,
            label: w.name,
          }))
        })
    } else {
      workplaceOptions.value = []
      workplaceId.value = null
    }
  }

  return {
    getReport,
    tableColumns,
    refUserListTable,
    refetchData,
    fetchInstitutions,
    institutionId,
    workplaceId,
    institutionOptions,
    fetchWorkplaces,
    workplaceOptions,
    checkAll,
    beginDate,
    endDate,
    showImediato,
    reportHeaders,
    isSortDirDesc,
    sortBy,

  }
}
