import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  formatCurrencyInt, formatNumber, formatPercent, maskCnpj,
} from '@core/utils/utils'
import { format, startOfMonth } from 'date-fns'

export default function useUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'workplace.name', label: 'Unidade', sortable: true },
    {
      key: 'quantity', label: 'Plantões', sortable: true, formatter: formatNumber,
    },
    {
      key: 'quantity_physician', label: 'Plantonistas', sortable: true, formatter: formatNumber,
    },
    {
      key: 'amount', label: 'Máx. Lançados', formatter: formatCurrencyInt, sortable: true,
    },
    {
      key: 'payroll_day', label: 'Folha Estimada', formatter: formatCurrencyInt, sortable: true,
    },
    {
      key: 'percent_amount_payroll', label: 'L vs F', formatter: formatPercent, sortable: true,
    },
  ]
  const showImediato = ref(false)
  const reportList = ref([])
  const institutionId = ref(null)
  const workplaceId = ref(null)
  const isSortDirDesc = ref(true)
  const sortBy = ref('workplace.name')
  const reportHeaders = ref({
    total_amount: 0,
    total_payroll: 0,
  })
  const institutionOptions = ref([])
  const workplaceOptions = ref([])
  const dayDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))

  const checkAll = computed({
    get: () => {
      if (reportList.value.length === 0) return false
      return reportList.value.filter(user => user.checked).length === reportList.value.length
    },
    set: val => {
      reportList.value.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.checked = val
      })
    },
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const getReport = (ctx, callback) => {
    store
      .dispatch('app-reports/payrollDaily', {
        date: dayDate.value || undefined,
        show_imediato: showImediato.value ? 1 : 0,
        institution: institutionId.value ? institutionId.value.value : '',
        workplace: workplaceId.value ? workplaceId.value.value : '',
      })
      .then(response => {
        // eslint-disable-next-line no-param-reassign
        // response.data = {
        //   message: 'success',
        //   error: false,
        //   data: {
        //     total_amount_physician_duty: 90,
        //     total_payroll: 300,
        //     workplaces: [
        //       {
        //         quantity_physician_duty: 4000000000,
        //         amout_physician_duty: 6000000,
        //         quantity_physician: 100000,
        //         institution: {
        //           id: '1bc42b13-7366-48c6-9703-a7d5fa129ef9',
        //           name: 'Galhardo-Torres 15',
        //         },
        //         workplace: {
        //           id: '87663f85-33c6-4a2e-b798-ebbebd28ef75',
        //           name: 'Hge teste1',
        //         },
        //         payroll_day: 0,
        //         percent_amount_requested_amount_payroll: 0,
        //       },
        //       {
        //         quantity_physician_duty: 2,
        //         amout_physician_duty: 30,
        //         quantity_physician: 1,
        //         institution: {
        //           id: '1bc42b13-7366-48c6-9703-a7d5fa129ef9',
        //           name: 'Galhardo-Torres 15',
        //         },
        //         workplace: {
        //           id: 'cfe3d9a0-3514-4a19-aaf4-c3e726f1f049',
        //           name: 'Hospital Unimed',
        //         },
        //         payroll_day: 133.33,
        //         percent_amount_requested_amount_payroll: 22.5,
        //       },
        //       {
        //         institution: {
        //           id: '1bc42b13-7366-48c6-9703-a7d5fa129ef9',
        //           name: 'Galhardo-Torres 15',
        //         },
        //         workplace: {
        //           id: '34d6782d-1918-40e8-8275-6996a327f9ea',
        //           name: 'Upa Sim 28-02 02',
        //         },
        //         payroll_day: 66.66,
        //         amount_physician_duty: 0,
        //         amount_requested: 0,
        //         percent_amount_requested_amount_payroll: 0,
        //         percentamount_requested_amount_physician_duty: 0,
        //       },
        //       {
        //         institution: {
        //           id: '1bc42b13-7366-48c6-9703-a7d5fa129ef9',
        //           name: 'Galhardo-Torres 15',
        //         },
        //         workplace: {
        //           id: '0a4cead3-80cf-4997-b162-983d7a49346c',
        //           name: 'Upa egtr',
        //         },
        //         payroll_day: 100,
        //         amount_physician_duty: 0,
        //         amount_requested: 0,
        //         percent_amount_requested_amount_payroll: 0,
        //         percentamount_requested_amount_physician_duty: 0,
        //       },
        //     ],
        //   },
        // }
        const workplaces = _.orderBy(response.data.data.workplaces, [sortBy.value], [isSortDirDesc.value ? 'desc' : 'asc'])
        reportList.value = workplaces
        reportHeaders.value = {
          total_amount: response.data.data.total_amount,
          total_payroll: response.data.data.total_payroll,
        }
        callback(reportList.value)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchInstitutions = () => {
    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })
  }

  const fetchWorkplaces = () => {
    if (institutionId.value) {
      store.dispatch('app-reports/fetchWorkplaceByInstitution', { institution_id: institutionId.value.value })
        .then(response => {
          workplaceOptions.value = response.data.data.map(w => ({
            value: w.id,
            label: w.name,
          }))
        })
    } else {
      workplaceOptions.value = []
      workplaceId.value = null
    }
  }

  return {
    getReport,
    tableColumns,
    refUserListTable,
    refetchData,
    fetchInstitutions,
    institutionId,
    workplaceId,
    institutionOptions,
    fetchWorkplaces,
    workplaceOptions,
    checkAll,
    dayDate,
    showImediato,
    reportHeaders,
    isSortDirDesc,
    sortBy,
  }
}
