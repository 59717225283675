<template>
  <div>
    <div>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="3"
            xl="2"
          >
            <div class="d-flex flex-column mt-2 ml-1">
              <div>
                Exibir empresa Imediato?
              </div>
              <b-form-checkbox
                :checked="showImediato"
                class="custom-control-secondary"
                name="check-button"
                switch
                @input="showImediato = !showImediato;"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <b-row class="justify-content-end">
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <label for="begin-date">Início em</label>
                <b-form-datepicker
                  id="begin-date"
                  v-model="beginDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="pt-BR"
                  label-help=""
                  placeholder=""
                  :min="new Date('2024-01-01')"
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <label for="end-date">Fim em</label>
                <b-form-datepicker
                  id="end-date"
                  v-model="endDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="pt-BR"
                  placeholder=""
                  label-help=""
                  :min="new Date('2024-01-01')"
                />
                <small
                  v-if="invalidInterval"
                  class="text-danger"
                >Deve estar no mesmo mês
                </small>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="4"
            xl="3"
          >
            <label for="institution-id">Instituição</label>
            <v-select
              id="institution-id"
              v-model="institutionId"
              name="institution-id"
              :options="institutionOptions"
              label="label"
              item-value="value"
              item-text="label"
              @input="fetchWorkplaces"
            >
              <span slot="no-options">Nenhuma instituição encontrada.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="3"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="workplace-id">Unidade</label>
                <v-select
                  id="workplace-id"
                  v-model="workplaceId"
                  name="workplace-id"
                  :options="workplaceOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  :disabled="!institutionId"
                >
                  <span slot="no-options">Nenhuma unidade encontrado.</span>
                </v-select>
              </div>
              <div class="d-flex align-items-end justify-content-end mt-2 mx-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mx-1"
                  @click="loadReport"
                >
                  Buscar
                  <feather-icon
                    icon="SearchIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="d-flex report-headers justify-content-between w-100">
        <div class="report-header">
          Lançado
        </div>
        <div class="report-header-value">
          {{ formatCurrencyInt(reportHeaders.total_amount) }}
        </div>
        <div class="report-header">
          Adiantado
        </div>
        <div class="report-header-value">
          {{ formatCurrencyInt(reportHeaders.total_amount_requested) }}
        </div>
        <div class="report-header">
          Folha
        </div>
        <div class="report-header-value">
          {{ formatCurrencyInt(reportHeaders.total_payroll) }}
        </div>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="getReport"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="(item) => { item.checked = !item.checked }"
      >
        <template
          #cell(workplace.name)="{ item }"
        >
          <div>{{ item.workplace.name }}</div>
          <div class="font-weight-bold institution-name">
            {{ item.institution.name }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BButton, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  formatCurrency, formatCurrencyInt, formatDateTimeDDMMYYHHMM, formatDateTimeDDMMYYYY, pad,
} from '@core/utils/utils'
import vSelect from 'vue-select'
import reportStoreModule from '@/views/report/reportStoreModule'
import { parse } from 'date-fns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import usePhysicianDutyOnPayroll from './usePhysicianDutyOnPayrollConsolidated'

export default {
  components: {
    BFormCheckbox,
    BFormDatepicker,
    BRow,
    BCol,
    BTable,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  methods: { formatCurrencyInt },
  setup() {
    const REPORTS_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const loading = ref(false)
    const invalidInterval = ref(false)

    const {
      getReport,
      fetchInstitutions,
      tableColumns,
      totalItems,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      checkAll,
      showImediato,
      institutionId,
      workplaceId,
      institutionOptions,
      workplaceOptions,
      fetchWorkplaces,
      beginDate,
      endDate,
      reportHeaders,
    } = usePhysicianDutyOnPayroll()

    fetchInstitutions()

    const validateInterval = () => {
      const start = parse(beginDate.value, 'yyyy-MM-dd', new Date())
      const end = parse(endDate.value, 'yyyy-MM-dd', new Date())
      return start.getMonth() === end.getMonth()
    }

    watch([beginDate, endDate], () => {
      invalidInterval.value = !validateInterval()
    })

    const loadReport = () => {
      loading.value = true
      if (!validateInterval()) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Início/Fim em meses diferentes',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      refetchData()
    }

    return {
      workplaceOptions,
      fetchWorkplaces,
      checkAll,
      loading,
      beginDate,
      endDate,
      getReport,
      tableColumns,
      reportHeaders,
      totalUsers: totalItems,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      showImediato,
      // Filter
      avatarText,
      // UI
      invalidInterval,
      loadReport,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      formatDateTimeDDMMYYYY,
      institutionId,
      institutionOptions,
      workplaceId,
      pad,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dates-row {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

tr:has(.custom-checkbox.checked) {
  background-color: rgba(98, 98, 98, 0.05);
}

.table-responsive {
  min-height: 200px;
}
.invoice-date {
  margin-top: 2px;
  font-size: 0.757rem;
  font-weight: 400;
  line-height: 0.857rem;
  div {
    font-size: 0.957rem;
    line-height: 0.757rem;
  }
}
h5 {
  margin-bottom: 0;
}
.report-headers {
  .report-header {
    text-align: center;
    font-size: 0.857rem;
    text-transform: uppercase;
    padding: 0.72rem 2rem;
    font-weight: bold;
    background-color: #575756 !important;
    color: rgb(255, 255, 255);
    flex-grow: 1;
  }
  .report-header-value {
    border-top: 1px solid #ebe9f1;
    font-size: 0.857rem;
    padding: 0.72rem 2rem 0.72rem 2rem;
    font-weight: bold;
    text-align: left;
    flex-grow: 1;
  }
}

tr {
  .institution-name {
    opacity: 0;
    height: 0;
  }
  &:hover {
    .institution-name {
      opacity: 1;
      height: auto;
      transition: 300ms ease all;
    }
  }
}
</style>
